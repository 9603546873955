


























import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      action: null,
      isActive: false, // TODO
    };
  },
  watch: {
    isActive(to) {
      if (to === false) {
        this.action = null;
      }
    },
  },
});
